export const APP_ID = 'SHELVES'
export const APP_ENTITY = 'SHELVES'

export const defaultDevtoolsOptions = {
  name: 'Shelves',
  enabled: localStorage.getItem('devtools') === 'true',
}

export const ROUTER_BASENAME = '/shelves'

export const SHELVES_ROUTES = {
  HOME: ROUTER_BASENAME,
}
