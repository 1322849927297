import Router from '@/Router';
import useSidebar from '@/hooks/useSideBar';
import { BrowserRouter } from 'react-router-dom';
import FooterVersion from '../FooterVersion/FooterVersion';
import { GridContainer, SideBar } from './Layout.styles';

const Layout = () => {
  useSidebar();

  return (
    <SideBar data-testid="shelves-layout">
      <GridContainer sidebar type="fluid">
        <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
          <Router />
        </BrowserRouter>
        <FooterVersion />
      </GridContainer>
    </SideBar>
  );
};

export default Layout;
