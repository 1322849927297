import { LoadingDots } from '@hexa-ui/components';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const BASENAME = '/shelves';

const Router = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={`${BASENAME}/`}
        element={
          <Suspense fallback={<LoadingDots />}>
            <h1>Hello World! This is Shelves</h1>
          </Suspense>
        }
      />
    </Routes>
  );
};
export default Router;
